import Vue from 'vue'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import 'element-ui/lib/theme-chalk/display.css';

import VueAwesomeSwiper from 'vue-awesome-swiper'
import 'swiper/dist/css/swiper.css'

import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'

Vue.use(ElementUI);

Vue.use(VueAwesomeSwiper);

Vue.config.productionTip = false

Vue.prototype.SrcUrl = 'https://cdnunified.liuyanniangzhi.com/uploads/'

router.beforeEach(function(to, from, next) {
  // console.log(to)
  // store.state.pageName=to.name
  store.commit('setPageName', to.fullPath)
  next()
})

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
