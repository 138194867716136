import { render, staticRenderFns } from "./xNav.vue?vue&type=template&id=193f4ea0&scoped=true"
import script from "./xNav.vue?vue&type=script&lang=js"
export * from "./xNav.vue?vue&type=script&lang=js"
import style0 from "./xNav.vue?vue&type=style&index=0&id=193f4ea0&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "193f4ea0",
  null
  
)

export default component.exports