
import { Loading } from 'element-ui'
import axios from 'axios';

let loading
const instance = axios.create({
  baseURL: process.env.VUE_APP_BASE_URL + process.env.VUE_APP_BASE_API, // 设置基础 API 地址
  timeout: 5000, // 设置请求超时时间
  headers: {
    'Content-Type': 'application/json;charset=UTF-8',
  },
});



// 添加请求拦截器
instance.interceptors.request.use(config => {
  // 在发送请求之前做些什么，例如添加 Token
  // config.headers.Authorization = `Bearer ${token}`;
  loading=Loading.service({ fullscreen: true })
  return config;
}, error => {
  // 对请求错误做些什么
  return Promise.reject(error);
});

// 添加响应拦截器
instance.interceptors.response.use(response => {
  // 对响应数据做点什么
  loading && loading.close()
  if(response.data.code!=200){
    alert('服务器故障')
  }
  return response.data;
}, error => {
  // 对响应错误做点什么
  return Promise.reject(error);
});

export default instance;
