<template>
  <div class="home">
    <swiper class="hidden-xs-only" :options="bannerSwiperOption" ref="bannerSwiper">
      <swiper-slide v-for="item in banner" :key="item.id"
        ><img style="width: 100%" :src="item.cover" alt=""
      /></swiper-slide>
      <div class="swiper-pagination" slot="pagination"></div>
    </swiper>
    <swiper class="hidden-sm-and-up" :options="bannerSwiperOption" ref="mobBannerSwiper">
      <swiper-slide v-for="item in mobBanner" :key="item.id"
        ><img style="width: 100%" :src="item.cover" alt=""
      /></swiper-slide>
      <div class="swiper-pagination" slot="pagination"></div>
    </swiper>
    <div class="product">
      <div class="title">
        <div>
          <img src="@/assets/flower.png" alt="" />
          <span>奢养美肌</span>
        </div>
        <img src="@/assets/down.png" alt="" />
      </div>
      <div class="productMain">
        <el-row :gutter="10">
          <el-col :xs="24" :sm="10" :md="10" :lg="10" :xl="10">
            <div class="slogan">
              <h2>一涂一抹 源美菁萃</h2>
              <el-button plain
                ><router-link to="/"
                  >前往探索<span>⇢</span></router-link
                ></el-button
              >
            </div>
          </el-col>
          <el-col :xs="24" :sm="14" :md="14" :lg="14" :xl="14">
            <swiper :options="swiperOption" ref="mySwiper">
              <swiper-slide v-for="item in productlist[0].childList" :key="item.id">
                <div class="slidebox" @click="toProductDetail(item.id)">
                  <img :src="item.img" alt="" />
                  <div>{{item.name}}</div>
                </div>
              </swiper-slide>
              <div class="swiper-scrollbar" slot="scrollbar"></div>
            </swiper>
          </el-col>
        </el-row>
      </div>
    </div>
    <div class="grayline"></div>
    <div class="product">
      <div class="title">
        <div>
          <img src="@/assets/flower.png" alt="" />
          <span>臻膳养体</span>
        </div>
        <img src="@/assets/down.png" alt="" />
      </div>
      <div class="productMain">
        <el-row :gutter="10" class="flex-direction">
          <el-col
            :xs="24"
            :sm="14"
            :md="14"
            :lg="14"
            :xl="14"
            style="direction: rtl"
          >
            <!-- <img
              class="img"
              style="height: 5.9rem"
              src="@/assets/img1.png"
              alt=""
            /> -->
            <swiper :options="swiperOption" ref="mySwiper">
              <swiper-slide v-for="item in productlist[1].childList" :key="item.id">
                <div class="slidebox" @click="toProductDetail(item.id)">
                  <img :src="item.img" alt="" />
                  <div>{{item.name}}</div>
                </div>
              </swiper-slide>
              <div class="swiper-scrollbar" slot="scrollbar"></div>
            </swiper>
          </el-col>
          <el-col :xs="24" :sm="10" :md="10" :lg="10" :xl="10">
            <div class="slogan" style="margin-left: 0.68rem">
              <h2>一饮一啄 焕美新生</h2>
              <el-button plain
                ><router-link to="/"
                  ><span>⇠</span>前往探索</router-link
                ></el-button
              >
            </div>
          </el-col>
        </el-row>
      </div>
    </div>
    <div class="grayline"></div>
    <div class="product">
      <div class="title">
        <div>
          <span>元氧美丽</span>
        </div>
        <img src="@/assets/down.png" alt="" />
      </div>
      <div class="productMain">
        <el-row :gutter="10">
          <el-col :xs="24" :sm="10" :md="10" :lg="10" :xl="10">
            <div class="slogan">
              <h2>一松一弛 寻美之境</h2>
              <el-button plain
                ><router-link to="/"
                  >前往探索<span>⇢</span></router-link
                ></el-button
              >
            </div>
          </el-col>
          <el-col :xs="24" :sm="14" :md="14" :lg="14" :xl="14">
            <video style="height: 6.05rem" :src="SrcUrl +'/video/ca07af2ef11b1e8e656670bbb210cded.mp4'" controls poster="@/assets/1920_1.jpg"></video>
            <!-- <img
              class="img"
              style="height: 6.05rem"
              src="/video/1920_1.jpg"
              alt=""
            /> -->
          </el-col>
        </el-row>
      </div>
    </div>
    <div class="grayline"></div>
    <div class="product">
      <div class="title">
        <div>
          <span>品质新生</span>
        </div>
        <img src="@/assets/down.png" alt="" />
      </div>
      <div class="productMain">
        <el-row :gutter="10" class="flex-direction">
          <el-col
            :xs="24"
            :sm="14"
            :md="14"
            :lg="14"
            :xl="14"
            style="direction: rtl"
          >
            <video style="height: 6.05rem" :src="SrcUrl+'/video/530ceed70ad1d28f1fe480e898ba7736.mp4'" controls poster="@/assets/1920_2.jpg"></video>
            <!-- <img
              class="img"
              style="height: 6.05rem"
              src="/video/1920_2.jpg"
              alt=""
            /> -->
          </el-col>
          <el-col :xs="24" :sm="10" :md="10" :lg="10" :xl="10">
            <div class="slogan" style="margin-left: 0.68rem">
              <h2>一动一静 美之天成</h2>
              <el-button plain
                ><router-link to="/"
                  ><span>⇠</span>前往探索</router-link
                ></el-button
              >
            </div>
          </el-col>
        </el-row>
      </div>
    </div>

    <div class="grayline"></div>
    <div class="product news">
      <div class="title">
        <div>
          <span>新闻咨询</span>
        </div>
        <img src="@/assets/down.png" alt="" />
      </div>
      <div class="productMain" v-if="NewsList.length">
        <swiper class="NewsSwiper" :options="NewsSwiperOption" ref="NewsSwiper">
          <swiper-slide v-for="item,index in NewsList" :key="index" :class="'id'+item.id">
            <!-- <div class="newsBox" @click="newsDetail_url(item.id)"> -->
            <div class="newsBox" :class="'id'+item.id">
              <!-- <img src="@/assets/newsimg.png" alt="" /> -->
              <div :style="{'background-image':'url('+SrcUrl+item.cover+')'}" :class="'id'+item.id"></div>
              <h4 :class="'id'+item.id">
                {{item.title}}
              </h4>
              <p :class="'id'+item.id">{{item.created_at}}</p>
            </div>
          </swiper-slide>
          <div class="swiper-pagination" slot="pagination"></div>
          <div class="swiper-button-prev" slot="button-prev"></div>
          <div class="swiper-button-next" slot="button-next"></div>
        </swiper>
      </div>
    </div>
    <el-dialog
      class="newsDialog"
      :title="newsTitle"
      :visible.sync="DialogVisible"
      center>
      <div class="newsDialogContent" v-html="NewsDetail"></div>
    </el-dialog>
    <!-- <HelloWorld msg="Welcome to Your Vue.js App"/> -->
  </div>
</template>

<script>
// @ is an alias to /src
// import HelloWorld from '@/components/HelloWorld.vue'
import {news_url,newsDetail_url} from '@/request/apis.js';
export default {
  name: "HomeView",
  data() {
    return {
      newsTitle:'',
      DialogVisible:false,
      banner: [{ cover: "img/banner.png" ,id:1}, { cover: "img/banner.png" ,id:2}],
      mobBanner: [{ cover: "img/bannerMob-1.jpg" ,id:1}, { cover: "img/bannerMob-2.jpg" ,id:2}],
      bannerSwiperOption: {
        autoHeight: true,
        paginationClickable: true,
        loop: true,
        autoplay: {
          delay: 3000,
          stopOnLastSlide: false,
          disableOnInteraction: false,
        },
        pagination: {
            el: '.swiper-pagination',
            clickable: true
          }
      },
      swiperOption: {
        autoHeight: true,
        slidesPerView: 2,
        spaceBetween: 20,
        scrollbar: {
          el: ".swiper-scrollbar",
          // hide: true
        },
        grabCursor: true,
      },
      productlist:[{
        parentId:1,
        name:'',
        childList:[{
          name:'紧致滋润美乳霜',
          img:'img/product1.png',
          id:1
        }
        // ,{
        //   name:'多肽紧致精华美乳喷雾',
        //   img:'img/product0.png',
        //   id:0
        // }
      ]
      },{
        parentId:2,
        name:'',
        childList:[{
          name:'γ-氢基丁酸葛根多肽饮',
          img:'img/product2.png',
          id:2
        }]
      }],
      NewsSwiperOption: {
        slidesPerView: "auto",
        centeredSlides: true,
        loop: true,
        pagination: {
          el: ".swiper-pagination",
          dynamicBullets: true,
          clickable: true,
        },
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
        },
        on:{
          click:(e)=>{
            if(~e.target.className.indexOf('id')){
              this.newsDetail_url(e.target.className.match(/\d+/)[0])
            }
          }
        }
      },
      NewsList:[],
      NewsDetail:'',
    };
  },
  components: {
    // HelloWorld,
  },
  methods: {
    toProductDetail(id){
      this.$router.push({name:'productDetail',params:{itemId:id}})
    },
    newsDetail_url(id){
      newsDetail_url({id:id}).then((res)=>{
          if(res.code==200){
            this.NewsDetail=res.data.content
            this.newsTitle=res.data.title
            this.DialogVisible=true
            // this.$alert(res.data.content, res.data.title, {
            //   customClass:'newsDialog',
            //   showConfirmButton:false,
            //   dangerouslyUseHTMLString: true
            // });
          }
      })
    }
  },
  mounted(){
        // 获取所有视频元素
        const videos = document.querySelectorAll('video');

        // 为每个视频添加播放事件监听器
        videos.forEach(video => {
            video.addEventListener('play', function() {
                // 当前视频正在播放，暂停其他所有视频
                videos.forEach(otherVideo => {
                    if (otherVideo !== this) { // 确保不是当前正在播放的视频
                        otherVideo.pause();
                    }
                });
            });
        });

        news_url({article_cate_id:3 , page:1 }).then((res)=>{
          if(res.code==200){
            this.NewsList=res.data.data
          }
        })
  }
};
</script>
<style  lang="scss">
.el-message-box.newsDialog{
  width: 96%;
  max-width: 800px;
  margin-top:10vh;
  .el-message-box__title{font-size: .28rem;text-align: center;}
  .el-message-box__content{
    height: 66vh;
    overflow-y: auto;
    font-size: .2rem;
    p{line-height: 2em;}
    img{width: 100%;}
  }
}
.el-dialog__wrapper.newsDialog{
  .el-dialog{
    width: 96%;
    max-width: 800px;
  }
  .el-dialog__body{
    padding: .1rem .3rem;
  }
  .el-dialog__title{
    font-size: .22rem;
    font-weight: bold;
  }
  .newsDialogContent{
    font-size: .2rem;
    line-height: 1.7em;
    img{width: 100%;}
  } 
}

</style>
<style lang="scss" scoped>
.grayline {
  width: 100%;
  height: 0.02rem;
  background-color: #eee;
}
.product {
  max-width: 1600px;
  box-sizing: border-box;
  margin: 0 auto;
  padding: 0.37rem 0.5rem 0.47rem;
  ::v-deep .swiper-container-horizontal > .swiper-scrollbar {
    bottom: 0;
  }
  ::v-deep .swiper-scrollbar {
    background: #cf000033;
  }
  ::v-deep .swiper-scrollbar-drag {
    background: #cf0000;
  }
  .title {
    > div {
      span {
        font-size: 0.55rem;
        background: linear-gradient(to bottom, #fd6565, #dd4645);
        background-clip: text;
        color: transparent; /* 这会使文本颜色透明，以便背景渐变可见 */
      }
      img {
        height: 0.7rem;
        vertical-align: top;
        margin-right: 0.16rem;
      }
    }
    > img {
      height: 0.5rem;
      margin-top: 0.1rem;
      margin-bottom: 0.4rem;
    }
  }
  .slogan {
    text-align: left;
    color: #000;
    padding-top: 1.4rem;
    h2 {
      font-size: 0.6rem;
      font-family:  "宋体",serif;
      font-weight: 100;
      margin-bottom: 0.3rem;
    }
    button {
      border-radius: 0;
      font-size: 0.24rem;
      width: 2.8rem;
      padding: 0.1rem 0.2rem;
      border-color: #666;
      a {
        color: #000;
        display: flex;
        justify-content: space-between;
        align-items: center;
        span {
          font-size: 0.3rem;
        }
      }
    }
  }
  .slidebox {
    background: linear-gradient(48deg, #fbfbfa, #f5f5f5);
    width: 4.5rem;
    width: 100%;
    padding: 0.4rem 0.2rem 0.3rem;
    box-sizing: border-box;
    text-align: center;
    img {
      max-height: 4.3rem;
      max-width: 4.1rem;
      width: 100%;
      filter: drop-shadow(.1rem .08rem .1rem #00000059);
      margin-bottom: 0.1rem;
    }
    > div {
      font-size: 0.28rem;
    }
  }
}
.news {
  max-width: 1920px;
  padding-left: 0;
  padding-right: 0;
  background: url(@/assets/newsBg.jpg) no-repeat center center/cover;
  .NewsSwiper {
    .newsBox {
      padding: 0 0.75rem 0.5rem;
      img {
        width: 100%;
        border-radius: 0.16rem;
        border-bottom: 0.13rem solid #9d9d9d;
      }
      div{
        padding-top:54.85%;
        border-bottom: 0.13rem solid #9d9d9d;
        border-radius: 0.16rem;
        background: #666 url(@/assets/newsimg.png) center center/cover;
      }
      h4,
      p {
        display: none;
      }
      h4 {
        font-size: 0.26rem;
        margin: 0.26rem 0.2rem;
      }
      p {
        font-size: 0.17rem;
      }
    }
    .swiper-slide {
      width: 46.354167%;
    }
    .swiper-slide-active {
      img {
        border-bottom-color: #cd0000 !important;
      }
      div{border-bottom-color: #cd0000 !important;}
      h4,
      p {
        display: block;
      }
    }
    .swiper-pagination {
      ::v-deep .swiper-pagination-bullet.swiper-pagination-bullet-active {
        background-color: #d00000;
      }
    }
    .swiper-button-prev,
    .swiper-button-next {
      top: 32%;
      filter: invert(100%) sepia(100%) hue-rotate(310deg) saturate(1000%) brightness(100%);
    }
    .swiper-button-prev {
      left: 26%;
    }
    .swiper-button-next {
      right: 26%;
    }
  }
}
@media (min-width: 768px) and (max-width: 1919px) {
  .img,video {
    width: 100%;
    height: auto !important;
  }
}
@media (max-width: 767px) {
  .grayline {
    // margin-bottom: 0.14rem;
  }
  .product {
    padding: 0.3rem;
    ::v-deep .swiper-container-horizontal > .swiper-scrollbar {
      height: 0.03rem;
    }
    .title {
      > div {
        span {
          font-size: 0.45rem;
        }
        img {
          height: 0.52rem;
          vertical-align: sub;
          margin-right: 0.14rem;
        }
      }
      > img {
        height: 0.33rem;
        margin-bottom: 0.2rem;
      }
    }
    .flex-direction .slogan {
      margin-left: 0 !important;
    }
    .slogan {
      padding-top: 0.188rem;
      padding-top: 0;
      display: flex;
      justify-content: space-between;
      align-items: center;
      flex-direction: column;
      margin-bottom: 0.468rem;
      margin-bottom: 0.234rem;
      h2 {
        display: inline-block;
        font-size: 0.468rem;
        font-size: 0.375rem;
        margin-bottom: 0;
      }
      button {
        display: none;
        font-size: 0.28rem;
        width: 2.34rem;
        a span {
          font-size: 0.375rem;
        }
      }
    }
    .slidebox {
      padding: 0.1875rem 0.1rem 0.14rem;
      img {
        max-height: 2.8rem;
        max-width: 100%;
      }
      > div {
        font-size: 0.234rem;
      }
    }
    .img,video {
      height: auto !important;
      width: 100%;
    }
    .flex-direction {
      display: flex;
      flex-direction: column-reverse;
    }
  }
  .news {
    .NewsSwiper {
      .swiper-button-prev{
        left: 0;
      }
      .swiper-button-next{
        right: 0;
      }
      .swiper-button-prev, .swiper-button-next{
        width: .3rem;
        height: .4rem;
        background-size: .3rem .4rem;
      }
      .newsBox{
        padding-left: .4rem;
        padding-right: .4rem;
      }
      .swiper-slide {
        width: 100%;
      }      
    }
  }
}
</style>
