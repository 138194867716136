// 将request.js整体导入
import request from './request'

//导航⬇
export const GetNavAPI = (params) => request.get('nav_list/1', {params});
//新闻⬇
export const news_url = (params) => request.get('16/get_article', {params});
//新闻详情⬇
export const newsDetail_url = (params) => request.get('get_article_detail', {params});

