<template>
    <div class="xNav">
        <div class="pc hidden-xs-only">
            <el-menu
            :default-active="activeIndex"
            class="el-menu-demo"
            mode="horizontal"
            :router="true"
            @select="handleSelect"
            background-color="#1d1d1d"
            text-color="#fff"
            active-text-color="#fff">
                <template v-for="(item, index) in NavList">
                    <el-menu-item v-if="!item.classify_id" :index="item.route" :key="'pc-'+index">{{ item.name }}</el-menu-item>
                    <!-- 渲染有子菜单的项 -->
                    <el-submenu v-else :index="item.classify_id" :popper-append-to-body="false" :key="'pc-'+index">
                        <template slot="title">{{ item.name }}</template>
                        <div>
                        <el-menu-item 
                            v-for="subItem in item.list" 
                            :key="'mob-child-'+subItem.id" 
                            :index="'/productDetail/' + subItem.id"
                        >{{ subItem.name }}</el-menu-item>
                        </div>
                        <!-- 假设每个分类都有一个固定的图片路径 -->
                        <img style="width:3rem;filter: drop-shadow(.1rem .08rem .1rem #00000059);" :src="'img/product' + item.classify_id + '.png'" alt="">
                    </el-submenu>
                </template>
                <el-menu-item class="callMe">
                    <a href="tel:4000128088">
                        <img alt="callMe" src="@/assets/callMe.png" style="width:.48rem;">
                        400-012-8088
                    </a>
                </el-menu-item>

            </el-menu>
        </div>
        <div class="moble hidden-sm-and-up">
            <div ref="mobMenuBox" @click="menuSwitch" style="
            height: 1.125rem;
            background-color: #1d1d1d;
            width:100vw;
            line-height: 1.125rem;
            position: fixed;
            top:0;
            z-index: 999999999999;">
                <img src="@/assets/logo.png" alt="logo" style="height:.75rem;vertical-align:middle;">
                <div
                    class="menu-button"
                    :class="{ 'opened': menuOpened }"
                >
                    <div class="bar"></div>
                    <div class="bar"></div>
                    <div class="bar"></div>
                </div>
            </div>
            <div style="width:60%;margin-left: auto;">
                <el-menu
                ref="mobMenu"
                mode="vertical"
                class="el-menu-vertical-demo"
                @open="handleOpen"
                @close="handleClose"
                @select="handleSelect"
                background-color="#1d1d1d"
                text-color="#fff"
                :router="true"
                :unique-opened="true"
                active-text-color="#e7b67d">
                    <el-submenu class="firstMenu" index="1">
                        <template slot="title">
                            <div
                                class="menu-button"
                                :class="{ 'opened': menuOpened }"
                            >
                                <div class="bar"></div>
                                <div class="bar"></div>
                                <div class="bar"></div>
                            </div>
                        </template>

                        <template v-for="(item, index) in NavList">
                            <el-menu-item v-if="!item.classify_id" :index="item.route" :key="'mob-'+index">{{ item.name }}</el-menu-item>
                            <!-- 渲染有子菜单的项 -->
                            <el-submenu v-else :index="'/productDetail-' + item.classify_id" :popper-append-to-body="false" :key="'mob-'+index">
                                <template slot="title">{{ item.name }}</template>
                                <el-menu-item 
                                    v-for="subItem in item.list" 
                                    :key="'mob-child-'+subItem.id" 
                                    :index="'/productDetail/' + subItem.id"
                                >{{ subItem.name }}</el-menu-item>
                            </el-submenu>
                        </template>

                        <el-menu-item class="callMe">
                            <a href="tel:4000128088">
                                <img alt="callMe" src="@/assets/callMe.png" style="width:.225rem;">
                                400-012-8088
                            </a>
                        </el-menu-item>
                    </el-submenu>
                </el-menu>
            </div>
        </div>
        <div class="vibility hidden-xs-only pcVibility" style="height:1rem;width:100%"></div>
        <div class="vibility hidden-sm-and-up mobVibility" style="height:1.125rem;width:100%"></div>
    </div>
</template>
<script>
// import {GetNavAPI} from '@/request/apis.js';
export default {
    name:'xNav',
    data() {
      return {
        activeIndex: '/home',
        mobActiveIndex:'/home',
        menuOpened: false,
        NavList:[{
                "name": "首页",
                "classify_id": '',
                "route": "/home",
                "list":[]
                },{
                "name": "妆护系列",
                "classify_id": '1',
                "route": "/home",
                "list": [{
                    "name": "【倍姿养】紧致滋润美乳霜",
                    "id": 1
                    }],
                },{
                "name": "膳养系列",
                "classify_id": '2',
                "route": "/home",
                "list": [{
                    "name": "γ-氢基丁酸葛根多肽饮",
                    "id": 2
                    }],
                },{
                "name": "品牌故事",
                "classify_id": '',
                "route": "/brandStory",
                "list":[]
                },{
                "name": "防伪查询",
                "classify_id": '',
                "route": "/securityCheck",
                "list":[]
                }]
      };
    },
    methods: {
        menuSwitch(){
            this.menuOpened=!this.menuOpened;
            this.menuOpened?this.$refs.mobMenu.open('1'):this.$refs.mobMenu.close('1')
        },
      handleSelect(key, keyPath) {
        console.log(key, keyPath);
        if(key){
            this.menuOpened=false;
            this.$refs.mobMenu.close('1')  
        }
      },
      handleOpen(key, keyPath) {
        console.log(key, keyPath);
        this.menuOpened=true;
      },
      handleClose(key, keyPath) {
        console.log(key, keyPath);
        // this.menuOpened=false;
      },
      handleClickOutside(event) {
        // 确保菜单是打开状态才进行检查
        // if (!this.menuOpened) return;
        // 获取菜单组件的根DOM元素
        const menuElement = this.$refs.mobMenu.$el; 
        const navElement = this.$refs.mobMenuBox; 

        // 检查点击是否发生在菜单组件外部
        if (!menuElement.contains(event.target) && !navElement.contains(event.target)) {
            this.menuOpened = false;
            this.$refs.mobMenu.close('1') 
        }
      },
      addEvent(){
            console.log(this.$store.state.pageName)
            this.activeIndex = this.$store.state.pageName;
            this.mobActiveIndex = this.$store.state.pageName;
            if(document.body.clientWidth<768){
                document.addEventListener('click', this.handleClickOutside);
                document.addEventListener('touchmove', this.handleClickOutside);            
            }            
        }

    },
    mounted(){
        // GetNavAPI().then((res)=>{
        //     console.log(res)
        //     })
        this.addEvent()
        window.onresize=()=>{this.addEvent()}
    },
    beforeDestroy(){
        if(document.body.clientWidth<768){
            document.removeEventListener('click', this.handleClickOutside);
            document.removeEventListener('touchmove', this.handleClickOutside);            
        }

  },
}
</script>
<style lang="scss" scoped>
.xNav{
    .pc{
        position: fixed;
        z-index: 9999999999;
        top: 0;
        left: 0;
        width: 100%;
        background-color: #1d1d1d;
        .el-menu{
            width: 100%;
            max-width: 1600px;
            margin: 0 auto;
            display: flex;
            flex-wrap: nowrap; 
            justify-content: flex-start; /* 默认靠左对齐 */
            .el-menu-item,
            ::v-deep .el-submenu__title
            {
               height: 1rem; 
               font-size: .22rem;
               line-height: .98rem;
               width: 2rem;
               padding: 0;
               a{display: inline-block;width: 100%; height: 100%;}
            }
            ::v-deep .el-menu--horizontal{
                background-color: #c33128db;
                width: 100%;
                left: 0!important;
                .el-menu{
                    padding: 0.5rem;
                    box-sizing: border-box;
                    background-color:  transparent!important;
                    display: flex;
                    justify-content: center;
                    >div{
                        display: flex;
                        flex-direction: row;
                        writing-mode: vertical-lr;
                        flex-wrap: wrap;
                        height: 3rem;
                        align-content: center;
                        justify-content: flex-start;
                        >li{
                            background-color: transparent!important;
                            writing-mode: horizontal-tb;
                            height: 0.6rem;
                            line-height: 0.6rem;
                            text-align: left;
                            width: 3rem;
                            font-size: .2rem;
                        }
                    }
                }
            }
            .callMe{
                background-color: #f5f5f5!important;
                color: #000!important;
                width: 2.73rem;
                font-size: .3rem;
                padding:0 .05rem;
                float: right;
                margin-left: auto;
            }
        }
        .el-menu.el-menu--horizontal{
            border-bottom:none;
        }
    }
    .moble{
        position: fixed;
        // height: 100vh;
        width: 100vw;
        // background-color: #00000071;
        z-index: 99999999;
        top: 0;
        left: 0;
        a{
            color: #fff;
        }
        .el-menu-vertical-demo{
            border-right: 0;
            .firstMenu{
                >::v-deep .el-submenu__title{
                    height: 1.125rem;
                }
                >::v-deep .el-menu{
                    .el-menu-item,
                    >.el-submenu .el-submenu__title{
                        border-top: 1px solid #444444;
                        font-size: .3rem;
                        height: .84rem;
                        line-height: .84rem;
                        padding: 0!important;
                    }
                    >.el-submenu .el-menu-item{
                        font-size: .26rem;
                        border-top-color: #44444460;
                    }
                }
            }

            
        }
        ::v-deep .el-icon-arrow-down{display: none;}
        .callMe{
            background-color: #f5f5f5!important;
            border: 1px solid #444444;
            a{
                color:#000;
                img{width: .27rem!important;vertical-align: text-top;}
            }
        }

        .menu-button {
            position: absolute;
            top: 0px;
            right: .3rem;
            padding: 0;
            height: 100%;
            height: 1.125rem;
            cursor: pointer;
            transition: .3s;
            background-color: #1d1d1d;
            width: .8rem;
            box-sizing: border-box;
            padding: 0.3625rem .2rem;
            display: flex;
            flex-direction: column;
            justify-content: space-around;

            .bar {
                position: relative;
                display: block;
                width: 100%;
                height: .03rem;
                background-color: #f5f5f5;
                border-radius: .1rem;
                transition: .3s;
            }

            // &:hover {
            //     .bar:nth-of-type(1) {
            //     transform: translateY(1.5px) rotate(-4.5deg);
            //     }
            //     .bar:nth-of-type(2) {
            //     opacity: .9;
            //     }
            //     .bar:nth-of-type(3) {
            //     transform: translateY(-1.5px) rotate(4.5deg);
            //     }
            // }

            &.opened {
                .bar:nth-of-type(1) {
                transform: translateY(.13rem) rotate(-45deg);
                }
                .bar:nth-of-type(2) {
                opacity: 0;
                width: 0;
                }
                .bar:nth-of-type(3) {
                transform: translateY(-.13rem) rotate(45deg);
                }

                // &:hover {
                // .bar:nth-of-type(1) {
                //     transform: translateY(13.5px) rotate(-40.5deg);
                // }
                // .bar:nth-of-type(2) {
                //     opacity: .1;
                // }
                // .bar:nth-of-type(3) {
                //     transform: translateY(-13.5px) rotate(40.5deg);
                // }
                // }
            }
        }

    }
}
</style>