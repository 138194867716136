<template>
  <div id="app">
    <xNav/>
    <router-view class="main"/>
    <xFooter/>
    <contact/>
  </div>
</template>
<script>
import xNav from '@/components/xNav';
import xFooter from '@/components/xFooter';
import contact from "@/components/contact";//在线客服悬浮按钮组

export default {
  name: "App",
  components: { xNav,xFooter,contact },
}
</script>
<style lang="scss">
.el-loading-spinner .path{
  stroke:#C33128!important;
}
.el-dialog__headerbtn:hover .el-dialog__close{
  color:#c33128;
}
.swiper-pagination-bullet-active{
  background:#c33128;
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  width: 100%; max-width: 1920px; overflow-x: hidden;margin: 0 auto;
}
.main{
  min-height: calc(100vh - 100px);
}
</style>
