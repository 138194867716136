import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    pageName:'/home'
  },
  getters: {
    getPageName:state => state.pageName
  },
  mutations: {
    setPageName(state,pageName){
      state.pageName=pageName
    }
  },
  actions: {
  },
  modules: {
  }
})
