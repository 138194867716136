import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/HomeView.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/home',
    name: 'home',
    component: HomeView
  },
  {
    path: '/brandStory',
    name: 'brandStory',
    component: () => import(/* webpackChunkName: "brandStory" */ '../views/brandStory.vue')
  },
  {
    path: '/securityCheck',
    name: 'securityCheck',
    component: () => import(/* webpackChunkName: "securityCheck" */ '../views/securityCheck.vue')
  },
  {
    path: '/productList',
    name: 'productList',
    component: () => import(/* webpackChunkName: "securityCheck" */ '../views/productList.vue')
  },
  {
    path: '/productDetail/:itemId',
    name: 'productDetail',
    component: () => import(/* webpackChunkName: "securityCheck" */ '../views/productDetail.vue')
  },
  {
    path: '*',
    redirect: { name: 'home' }
  }
]

const router = new VueRouter({
  scrollBehavior(to, from, savedPosition) {
    // 如果有保存的滚动位置（比如前进/后退按钮导致的导航），则使用它
    if (savedPosition) {
      return savedPosition;
    } else {
      // 否则，滚动到顶部
      return { x: 0, y: 0 };
    }
  },
  // base: "/dist/",//部署的子路径
  routes
})

export default router
